<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'

const props = defineProps<{
  fields: {
    media: VoixMediaFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    background: VoixTextFieldInterface
    backgroundPosition: VoixSelectFieldInterface
  }
  slice: SliceInterface
}>()

defineSlice({
  name: { label: 'Scrolling Gallery', group: 'Kimpton Scrolling Gallery', layouts: ['Kimpton'] },
  slots: [
    { name: 'gallery', allowedElements: ['SlicesKimptonScrollingGalleryImage'] },
  ],
  description: 'A features block',
  preview: 'SlicesBonitaFeaturesBlock.jpg',
  fields: {
  },
  templates: [{
    label: 'Main Example',
    fields: {},
  }],
})

// Templatizer Stuff
const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const templatizerGallery: any = [
  {
    title: { value: 'The Title' },
    media: { value: 'https://plus.unsplash.com/premium_photo-1670044658988-fca844bce315?q=80&w=1200&h=675&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  },
  {
    title: { value: 'The Title' },
    media: { value: 'https://images.unsplash.com/photo-1719813916163-ada504fd9660?q=80&w=1200&h=675auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  },
  {
    title: { value: 'The Title' },
    media: { value: 'https://images.unsplash.com/photo-1695128782705-e8a6a49ea5ed?q=80&w=1200&h=675auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  },
  {
    title: { value: 'The Title' },
    media: { value: 'https://images.unsplash.com/photo-1719451642957-172abf017863?q=80&w=1200&h=675&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  },
]

// Gallery Stuff
const gallerySubSlices = props.slice.elements?.filter((element: any) => element.slot === 'gallery') as SliceInterface[]

let numberOfSlides = gallerySubSlices.length
if (numberOfSlides === 0 && isVoixTemplatizer) {
  numberOfSlides = templatizerGallery.length
}

const swiperRef: Ref<SwiperType | null> = ref(null)
function setSwiperRef(swiper: SwiperType) {
  swiperRef.value = swiper
}

function next() {
  if (swiperRef.value)
    swiperRef.value.slideNext()
}

function prev() {
  if (swiperRef.value)
    swiperRef.value.slidePrev()
}
</script>

<template>
  <div class="relative py-24 ">
    <Swiper
      id="swiper"
      :modules="[Navigation, Pagination]"
      :slides-per-view="numberOfSlides > 3 ? 'auto' : 1"
      :loop="true"
      :space-between="5"
      :centered-slides="true"
      :slides-offset-before="1"
      :grab-cursor="true"
      :autoplay-stop-on-last="false"
      @swiper="setSwiperRef"
    >
      <SwiperSlide v-for="slice in gallerySubSlices" :key="slice.id">
        <VoixSlice :slice="slice" />
      </SwiperSlide>
      <template v-if="isVoixTemplatizer">
        <SwiperSlide v-for="(slide, key) in templatizerGallery" :key="key">
          <SlicesKimptonScrollingGalleryImage :fields="slide" />
        </SwiperSlide>
      </template>
    </Swiper>

    <div
      v-if="numberOfSlides > 1"
      class="absolute z-10 inset-0 pointer-events-none flex justify-center items-center"
    >
      <div class="flex justify-between w-full max-w-[800px]">
        <button class="pointer-events-auto bg-bonita-inverse lg:-translate-x-1/2" @click="prev">
          <Icon class="w-16 h-24 text-gray-700" name="bi:chevron-compact-left" />
        </button>
        <button class="pointer-events-auto bg-bonita-inverse lg:translate-x-1/2" @click="next">
          <Icon class="w-16 h-24 text-gray-700" name="bi:chevron-compact-right" />
        </button>
      </div>
    </div>
    <div
      v-if="numberOfSlides > 1"
      class="absolute z-20 inset-0 flex justify-center items-end p-8 pointer-events-none"
    >
      <div class="flex items-center space-x-2 pointer-events-auto">
        <template
          v-for="index in numberOfSlides"
          :key="index"
        >
          <button
            class=" rounded-full border  hover:opacity-100 duration-200"
            :class="{
              'bg-gray-400 border-0 w-1.5 h-1.5': swiperRef?.realIndex !== index - 1,
              'border-gray-500 border-2 w-2 h-2': swiperRef?.realIndex === index - 1,
            }"
            @click="swiperRef?.slideTo(index - 1)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-container {
    width: 100%;
    height: auto;
}

.swiper-slide {
  @apply w-auto h-auto flex justify-center
}
.swiper-wrapper {
  @apply items-stretch;
}
</style>
